<template>
    <div style="display: flex; flex-direction: column; min-height: 100vh; background-color: #F8F9FC;">
        <div style="flex: 1; overflow-y: auto;">
            <headComponent>
                <template #head>
                    <!-- 可以在此处添加或修改底部内容，如果不需要修改，可省略 -->
                </template>
            </headComponent>
          <div style="width: 1240px;margin: 0 auto;">
            <div style="margin: auto; width: 100%;">
                <el-breadcrumb separator="/" style="margin: 24px 0 ">
                    <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>智慧实验室</el-breadcrumb-item>
                </el-breadcrumb>
            </div>


            <div class="tab-box">
                <div class="tab-item" :style="{ borderBottom: tabIndex === 1 ? '2px solid #377bf3' : '' }"
                    @click="onTabClick(1)">
                    智慧实验室
                </div>
                <div class="tab-item" :style="{ borderBottom: tabIndex === 2 ? '2px solid #377bf3' : '' }"
                    @click="onTabClick(2)">
                    医学24项
                </div>

            </div>


            <!-- 公告 -->
            <div v-if="tabIndex === 1">
                <div v-for="noticeContents in noticeContent" :key="noticeContents.id"
                    @click="detailPage(noticeContents.id)" style="cursor: pointer">
                    <div style=" width:  100%; margin: 42px auto auto auto;border-radius: 12px;background-color: #ffffff;"
                        class="card">
                        <div style="padding: 30px;">
                            <p style="font-weight: bold">{{ noticeContents.name }}</p>
                            <p v-if="noticeContents && noticeContents.content"
                                style="color: rgba(0, 0, 0, 0.6); margin-top: 10px;">
                                <!-- <span v-html="getTruncatedContent(noticeContents.content)"></span>... -->
                            </p>
                            <p style="color: rgba(0, 0, 0, 0.6); margin-top: 10px">{{ noticeContents.updateTime }}</p>
                        </div>
                        <div style="padding: 2%;">
                            <img v-if="noticeContents.cover" style="width: 150px; height: 100px; display: block"
                                :src="noticeContents.cover" />
                        </div>
                    </div>
                </div>

                <div v-if="this.noticeContent.length <= 0">
                    <el-empty description="暂无数据" />
                </div>

                <div style="display: flex;justify-content: center;margin-top: 30px;">
                    <el-pagination :current-page="currentPage4" layout="total,  prev, pager, next, jumper"
                        :total="this.noticeContent.length">
                    </el-pagination>
                </div>
            </div>

            <!-- 新闻 -->
            <div v-if="tabIndex === 2">

                <div v-for="newsContents in newsContent" :key="newsContents.id" @click="detailPage(newsContents.id)"
                    style="cursor: pointer">
                    <div style="width: 100%;margin: 42px auto auto auto;border-radius: 12px;background-color: #ffffff;"
                        class="card">
                        <div style="padding: 30px;">
                            <p style="font-weight: bold">{{ newsContents.name }}</p>
                            <p v-if="newsContents && newsContents.content"
                                style="color: rgba(0, 0, 0, 0.6); margin-top: 10px;">
                                <!-- <span v-html="getTruncatedContent(newsContents.summary)"></span>... -->
                            </p>
                            <p style="color: rgba(0, 0, 0, 0.6); margin-top: 10px">{{ newsContents.updateTime }}</p>
                        </div>
                        <div style="padding: 2%;">
                            <img v-if="newsContents.cover" style="width: 150px; height: 100px; display: block"
                                :src="newsContents.cover" />
                        </div>
                    </div>
                </div>

                <div v-if="this.newsContent.length <= 0">
                    <el-empty description="暂无数据" />
                </div>

                <div style="display: flex;justify-content: center;margin-top: 30px;">
                    <el-pagination :current-page="currentPage4" layout="total,  prev, pager, next, jumper"
                        :total="this.newsContent.length">
                    </el-pagination>
                </div>
            </div>

          </div>

        </div>
        <bottomComponent>
            <template #footer> </template>
        </bottomComponent>
    </div>
</template>

<script>
import bottomComponent from "../bottomComponent.vue";
import headComponent from "../headComponent.vue";
import { laboratoryApi, medicalApi } from '@/api/message/message'
export default {
    created() {
        document.title = "智慧实验室";

    },
    mounted() {
        this.noticeList();

        this.newsList();

    },
    components: {
        bottomComponent,
        headComponent,
    },
    data() {
        return {
            pageNo: 1,
            circleUrl: "",
            tabIndex: 1,
            currentPage4: 0,

            noticeLists: {},
            noticeContent: [],

            newsLists: {},
            newsContent: [],

        };
    },

    methods: {
        //详情页
        detailPage(id) {
            console.log(id)
            this.$router.push("/laboratory/laboratoryDetail?id=" + id)
        },
        //智慧实验室
        noticeList() {
            laboratoryApi(this.pageNo).then(resp => {
                this.noticeLists = resp.data.data;
                this.noticeContent = resp.data.data.content
                console.log(resp)
            })
        },
        //医学24
        newsList() {
            medicalApi(this.pageNo).then(resp => {
                this.newsLists = resp.data.data;
                this.newsContent = resp.data.data.content
                console.log(resp)
            })
        },

        onTabClick(index) {
            this.tabIndex = index;
        },
        getTruncatedContent(content) {
            const maxLength = 150; // 设置最大字符数
            if (content.length <= maxLength) {
                return content;
            }
            return content.slice(0, maxLength);
        }
    },
};
</script>

<style scoped>
.tab-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.tab-item {
    padding: 5px;
    text-align: center;
    margin-right: 40px;
    cursor: pointer;
}

.card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}
</style>
