// file:api.js
// 1、导入axios以及拦截器
import service from "../request";

// 公告
export const noticeApi = (pageNo) => {
  return service({
    url: "/front/api/article/list",
    method: "post",
    headers: {
      token: localStorage.getItem("token"),
      debug: "true",
      plat: "web",
    },
    data: {
      pageNo,
      articleType: "NOTICE",
      platFlagPc: "true",
    },
  });
};

// 新闻
export const newsApi = (pageNo) => {
  return service({
    url: "/front/api/article/list",
    method: "post",
    headers: {
      token: localStorage.getItem("token"),
      debug: "true",
      plat: "web",
    },
    data: {
      pageNo,
      articleType: "NEWS",
      platFlagPc: "true",
    },
  });
};

// 新闻
export const messageApi = (pageNo) => {
  return service({
    url: "/front/api/article/list",
    method: "post",
    headers: {
      token: localStorage.getItem("token"),
      debug: "true",
      plat: "web",
    },
    data: {
      pageNo,
      articleType: "MESSAGE",
      platFlagPc: "true",
    },
  });
};

//智慧实验室
export const laboratoryApi = (pageNo) => {
  return service({
    url: "/front/api/article/list",
    method: "post",
    headers: {
      token: localStorage.getItem("token"),
      debug: "true",
      plat: "web",
    },
    data: {
      pageNo,
      articleType: "LABORATORY",
      platFlagPc: "true",
    },
  });
};

//医学二十四项
export const medicalApi = (pageNo) => {
  return service({
    url: "/front/api/article/list",
    method: "post",
    headers: {
      token: localStorage.getItem("token"),
      debug: "true",
      plat: "web",
    },
    data: {
      pageNo,
      articleType: "MEDICAL",
      platFlagPc: "true",
    },
  });
};

//健康中国行
export const healthyApi = (pageNo) => {
  return service({
    url: "/front/api/article/list",
    method: "post",
    headers: {
      token: localStorage.getItem("token"),
      debug: "true",
      plat: "web",
    },
    data: {
      pageNo,
      articleType: "HEALTHY",
      platFlagPc: "true",
    },
  });
};

//"用户手册
export const manualApi = (pageNo) => {
  return service({
    url: "/front/api/article/list",
    method: "post",
    headers: {
      token: localStorage.getItem("token"),
      debug: "true",
      plat: "web",
    },
    data: {
      pageNo,
      articleType: "MANUAL",
      platFlagPc: "true",
    },
  });
};

//软件下载
export const downloadApi = (pageNo) => {
  return service({
    url: "/front/api/article/list",
    method: "post",
    headers: {
      token: localStorage.getItem("token"),
      debug: "true",
      plat: "web",
    },
    data: {
      pageNo,
      articleType: "DOWNLOAD",
      platFlagPc: "true",
    },
  });
};

//接口对接
export const interfaceApi = (pageNo) => {
  return service({
    url: "/front/api/article/list",
    method: "post",
    headers: {
      token: localStorage.getItem("token"),
      debug: "true",
      plat: "web",
    },
    data: {
      pageNo,
      articleType: "INTERFACE",
      platFlagPc: "true",
    },
  });
};

// 详情页
export const detailPageApi = (id) => {
  return service({
    url: "/front/api/article/detail",
    method: "post",
    headers: {
      token: localStorage.getItem("token"),
      debug: "true",
      plat: "web",
    },
    data: {
      id,
    },
  });
};
