<template>
    <div id="div">
        <div style="padding: 30px;">
            <span>
                <h1 style="text-align: center;"> 关于我们</h1>
                <p>模拟医学是一种全新的医疗质量改进思路，它通过模拟技术创设高仿真模拟患者和各种医院工作情景，可以让医学专业人士反复练习，并且可以安全地创造及管理医院较难遇到的、具有挑战性的复杂情景，从而可以减少患者暴露于潜在医疗差错中的风险。
                    医学模拟技术在医疗教育、培训和实践中的革命性作用，并倡导模拟医学为患者、医疗从业人员和医疗机构带来的好处至关重要，促进其采用并融入整个医疗健康领域的日常学习和实践中，使患者和医护人员受益。然而，传统的模拟中心已无法满足当今科技进步的发展和培训需求，因此衍生了以前沿科技技术引领教学，辅助临床培训为思路的模拟医学智慧实验室建设，补充传统模拟医学中心功能上的不足，亟需借助于最新发展人工智能和虚拟技术提升模拟医学中心数字化、信息化和智能化的发展。
                </p>


                <p>
                    <span
                        style="font-weight: bold;">智慧模拟医学网平台</span>是利用新兴人工智能和虚拟现实技术的创新探索，为结合专委会项目落地和推动智慧模拟智慧实验室过程中面临的物理空间、技术规范、课程体系和评价体系系列标准化、规范化和系统化的难题，通过完全自主研发的信息管理架构设计、数据存储技术、虚拟内容引擎驱动技术和虚拟内容生成人工智能技术等4大核心技术攻关和工程技术的开发，实现跨平台虚拟课程资源的集成平台。
                    该平台可以兼容移动、网页、VR、MR等复杂多源的虚拟课程内容兼容存储与高流畅运行，用户可以通过手机、电脑和虚拟专用设备随时随地高流畅访问课程内容，学员学习和操作数据记录与用户数据画像绘制。该平台是国内首个跨医院、跨内容资源的一体化虚拟课程共享平台，该共享平台将为推动新一代模拟医学中心信息化、智慧化的整体解决方案应用与专科化课程建设发展提供强有力的技术和资源支撑，助力适应医疗健康系统不断变化的需求，最终提升医疗质量。
                </p>



            </span>

        </div>

    </div>
</template>

<script>
export default {
    created() {
        document.title = "关于我们";
    },
    data() {
        return {

        };
    },
    mounted() {

    },
    methods: {

    },
};
</script>

<style scoped>
/* 全局样式 */
body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 20px;
    background-color: #f4f4f4;
}

h2 {
    color: #333;
    margin-top: 20px;
    margin-bottom: 10px;
}

p {
    margin-bottom: 15px;
}

/* 模拟文档中的空行效果 */
p+p {
    margin-top: 10px;
}
</style>